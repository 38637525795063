/* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
*:where(:not(iframe, canvas, img, svg, video, input[type="checkbox"], sup):not(svg *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  Remove list styles (bullets/numbers)
  in case you use it with normalize.css
*/
ol, ul {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}

/* Removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

a {
  cursor: pointer;
}

a:hover {
  color: var(--header-menu-hover);
  transition: all 0.2s ease-in-out;
}

:root {
  /* COLOR SCHEMES */
  --primary-background-color: #2E313B;
  --secondary-background-color: #363945;
  --third-background-color: #23252d;
  --border-color: #494E5C;
  --primary-text-color: #A4A9BA;
  --secondary-text-color: #FFFFFF;
  --color-blue: #2374E1;
  --color-blue-hover: #1f68ca;
  --color-red: #FA6360;
  --hover-color: rgba(153,164,194,.13);
  --color-positive: #1bc47d;
  --color-negative: #fa6464;
  --light-gray-color: #3C404C;

  /* FONTS */
  --text-font: Roboto, "Helvetica Neue", sans-serif;
  --icon-font: "Material Icons";

  /* FONT WEIGHTS */
  --font-thin: 100;
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-bold: 700;

  /* FONT SIZES */
  --font-base: 1rem; /* 16px */
  --font-size-10px: 0.625rem;
  --font-size-11px: 0.688rem;
  --font-size-12px: 0.75rem;
  --font-size-14px: 0.875rem;
  --font-size-18px: 1.125rem;
  --font-size-20px: 1.25rem;
  --font-size-24px: 1.5rem;
  --font-size-30px: 1.875rem;
  --font-size-32px: 2rem;

  /*--main-background-color: #1F1F1F;*/
  /*--header-background-color: #27282A;*/
  /*--text-color: #E3E3E3;*/
  /*--sub-text-color: #8E8E8E;*/

  --scroll-width: 8px;
  --scroll-radius: 0.5rem;
  --scroll-track: var(--light-gray-color);
  --scroll-thumb: var(--border-color);
  --scroll-thumb-hover: var(--border-color);

  /*--header-menu-hover: #2374E1;*/
  /*--main-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;*/
}

/* width */
::-webkit-scrollbar {
  width: var(--scroll-width);
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scroll-track);
  /*border-radius: var(--scroll-radius);*/
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scroll-thumb);
  border-radius: var(--scroll-radius);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-thumb-hover);
}

input[type="datetime-local"] {
  cursor: pointer;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  color-scheme: dark;
}

html, body {
  font-size: var(--font-base);
  font-family: var(--text-font);
  font-weight: var(--font-regular);
  margin: 0;
  padding: 0;
  color: var(--primary-text-color);
  background-color: var(--primary-background-color);
  min-height: 100dvh;
  width: 100dvw;
  min-width: 360px;
  overflow: hidden;
  position: relative;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  & circle {
    stroke: var(--color-blue);
  }
}

.global_loader {
  position: absolute;
  z-index: 1000;
  top: 56px;
  left: 0;
  right: 0;
  height: 2px;


  & .mdc-linear-progress__bar {
    height: 2px;
  }

  & .mdc-linear-progress__buffer {
    height: 2px;
  }

  & .mdc-linear-progress__buffer-bar {
    background-color: var(--color-blue);
  }

  & .mdc-linear-progress__bar-inner {
    border-color: var(--secondary-background-color);
    height: 2px;
  }
}

#no-records-screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;

  & .mat-icon {
    width: 300px;
    height: 160px;
    font-size: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 25px -22px;
    margin-bottom: 2.5rem;
  }

  & h2 {
    font-weight: var(--font-bold);
    font-size: var(--font-size-24px);
    padding: 0 1rem 0.5rem 1rem;
  }

  & p {
    font-size: var(--font-base);
    padding: 0 1rem 0.5rem 1rem;
  }
}

p {
  font-size: var(--font-size-14px);
}

.cursor-pointer {
  cursor: pointer;
}

*[hidden] {
  display: none !important;
}

input[readonly] {
  opacity: 0.5;
  cursor: not-allowed;
  border: solid 1px transparent !important
}

.positive {
  color: var(--color-positive);
}

.negative {
  color: var(--color-negative);
}

.neutral {
  color: var(--primary-text-color);
}

.available {
  color: var(--color-blue);
}

nav {
  & a {
    position: relative;
  }
}

.bsense-dialog {
  overflow: auto;
  padding-right: 0.5rem;
  max-width: 800px;
}

.options {
  & .delete {
    &:hover {
      color: var(--color-negative);
    }
  }
}

.mdc-tooltip__surface {
  background-color: var(--secondary-background-color) !important;
  border-radius: 5px !important;
  border: solid 1px var(--border-color) !important;
  white-space: break-spaces;
}

#flash-message {
  border: solid 0.1rem var(--border-color);
  border-radius: 0.5rem;
  background-color: var(--secondary-background-color);
  padding: 1rem;
  position: absolute;
  bottom: -100px;
  left: 1rem;
  max-width: 350px;
  margin-left: auto;
  z-index: 5;
  transition: 0.2s bottom linear;
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 0.2rem;

  &.visible {
    bottom: 140px;
  }

  & .mat-icon {
    display: flex;
    place-content: center;
    width: 100%;
    height: 100%;
    font-size: var(--font-size-32px);
  }

  &.error {
    & .mat-icon {
      color: var(--color-negative);
    }
  }

  &.done {
    & .mat-icon {
      color: var(--color-positive);
    }
  }

  & p {
    display: flex;
    place-items: center;
  }
}

.fullscreen {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100dvh;
  width: 100dvw;
  z-index: 20;

  & > .table-groups {
      max-height: calc(100dvh - 60px) !important;
  }
}

#layout {
  display: grid;
  grid-template-areas:
    "layout-navbar"
    "layout-content"
    "layout-navigation";
  width: 100%;
  grid-template-rows: 60px 1fr 60px;
  height: 100%;

  & #layout-navbar {
    grid-area: layout-navbar;
    background-color: var(--secondary-background-color);
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: solid 1px var(--primary-background-color);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    position: relative;
    z-index: 10;

    & h2 {
      font-weight: var(--font-medium);
      font-size: 1.3rem;
      position: relative;

      &:after {
        position: absolute;
        content: "beta";
        top: -3px;
        right: 10px;
        font-weight: var(--font-medium);
        font-size: 9px;
        text-transform: uppercase;
        color: var(--color-negative);
      }

      & span {
        vertical-align: middle;
        cursor: pointer;
        border-radius: 5px;
        padding: 0.6rem;
        transition: 0.2s linear;

        &:hover {
          background-color: var(--hover-color);
        }
      }
    }

    & nav {
      display: flex;
      gap: 10px;
      margin-left: auto;

      & a {
        width: 40px;
        height: 40px;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 5px;
        transition: 0.2s ease-in-out;

        &.active {
          background-color: var(--primary-background-color);
        }

        &:not(.active):hover {
          background-color: var(--hover-color);
        }

        & .mat-icon {
          width: 32px;
          height: 32px;
          font-size: 2rem;
        }
      }
    }
  }

  & #layout-container {
    grid-area: layout-content;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100dvh - (60px * 2));
    padding: 1rem 0.3rem;
    width: 100%;
    place-items: center;

    @media screen and (min-width: 796px) {
      height: calc(100dvh - (60px* 1));
      padding: 1rem;
    }

    & .centered-container {
      max-width: 1128px;
      margin: auto;
      /*height: 100%;*/
    }
  }

  & #layout-navigation {
    grid-area: layout-navigation;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-background-color);
    border-top: solid 1px var(--primary-background-color);
    position: relative;
    z-index: 10;

    @media screen and (min-width: 796px) {
      & {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        border-top: 0;
      }
    }

    & nav {
      display: flex;
      gap: 10px;

      @media screen and (min-width: 796px) {
        & {
          gap: 30px;
        }
      }

      & a {
        width: 40px;
        height: 40px;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 5px;
        transition: 0.2s ease-in-out;

        &.active {
          background-color: var(--primary-background-color);
        }

        &:not(.active):hover {
          background-color: var(--hover-color);
        }

        & .mat-icon {
          width: 32px;
          height: 32px;
          font-size: 2rem;
        }
      }
    }
  }
}

#scroll-to {
  position: fixed;
  right: 2rem;
  bottom: 5rem;
  width: 50px;
  height: 50px;
  background-color: var(--secondary-background-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px var(--border-color);
  cursor: pointer;
  transition: 0.2s ease-in-out;
  opacity: 0;
  z-index: 10;

  &.show {
    opacity: 1;
  }

  &:hover {
    background-color: var(--hover-color);
  }

  & .mat-icon {
    font-family: var(--icon-font);
    font-size: var(--font-size-24px);
  }
}


/*.scrollable {*/
/*  scrollbar-gutter: stable;*/
/*}*/
.btn {
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;

  &:hover {
    background-color: var(--secondary-background-color);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.2;

    &:hover {
      background-color: transparent;
    }
  }
}

form {

  & .form-group {
    padding-bottom: 1.5rem;
    display: block;

    &:last-child {
      padding-bottom: 0;
    }

    & label {
      display: inline-block;
      font-weight: var(--font-medium);
      padding-bottom: 0.5rem;

      & .required {
        color: var(--color-red);
      }
    }

    & input, & textarea {
      display: block;
      width: 100%;
      line-height: 1.5rem;
      padding: 0.5rem 0.9rem;
      border: solid 1px var(--border-color);
      border-radius: 0.3rem;
      color: var(--secondary-text-color);
      background-color: var(--primary-background-color);
      font-weight: var(--font-thin);

      &:active, &:focus {
        border: solid 1px var(--color-blue);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--secondary-text-color);
        -webkit-box-shadow: 0 0 0px 40rem var(--primary-background-color) inset;
      }
    }

    & .error {
      font-size: 0.8rem;
      color: var(--color-red);
      font-weight: var(--font-medium);
      padding-top: 0.3rem;
      display: block;
      height: 0.3rem;
    }

    & button {
      margin-top: 1.5rem;
      display: block;
      width: 100%;
      line-height: 1.5rem;
      padding: 0.5rem 0.9rem;
      background-color: var(--color-blue);
      border-radius: 0.3rem;
      cursor: pointer;
      text-align: center;
      color: var(--secondary-text-color);
      font-weight: var(--font-bold);
      text-transform: uppercase;
      transition: 0.2s ease-in-out;

      &:not([disabled]):hover {
        background-color: var(--color-blue-hover);
      }

      &[disabled] {
        opacity: 0.3;
      }
    }
  }
}

.box {
  border: solid 0.1rem var(--border-color);
  border-radius: 0.5rem;
  background-color: var(--secondary-background-color);
  padding: 1rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  & > .title {
    margin-bottom: 0.5rem;
    border-bottom: 1px solid var(--primary-background-color);
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    & h2 {
      font-size: var(--font-size-24px);
      font-weight: var(--font-bold);
      margin-right: auto;
    }

    & .options {
      margin-left: auto;
      display: flex;

      & div {
        width: 32px;
        height: 32px;
        display: flex;
        border-radius: 5px;
        transition: 0.2s ease-in-out;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: var(--hover-color);
        }

        & .mat-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  & > p {
    font-size: var(--font-size-14px);
    font-weight: var(--font-light);
    padding-bottom: 1rem;
  }
}

.box-inverted {
  & > .title {
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--secondary-background-color);
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    & h2 {
      font-size: var(--font-size-20px);
      font-weight: var(--font-medium);
      color: var(--secondary-text-color);
      margin-right: auto;
    }

    & .options {
      margin-left: auto;

      & div {
        width: 32px;
        height: 32px;
        display: flex;
        border-radius: 5px;
        transition: 0.2s ease-in-out;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: var(--hover-color);
        }

        & .mat-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  & > p {
    font-size: var(--font-size-10px);
    font-weight: var(--font-light);
    padding-bottom: 0.5rem;
  }
}

.timeline {
  border-left: 1px solid var(--color-blue);
  /*margin-left: 1.2rem;*/
  padding-left: 1.2rem;
  padding-top: 1.5rem;

  .box {
    position: relative;
    margin-bottom: 2.5rem;
    transition: 0.2s ease-in-out;

    & p {
      padding-bottom: 0;
    }

    & .timeline-date {
      position: absolute;
      top: -25px;
      left: 0;
      font-weight: var(--font-medium);
      font-size: var(--font-size-10px);
      text-indent: 5px;

      &.spent {
        &:before {
          border-color: var(--color-negative);
          background-color: var(--color-negative);
        }

        &:after {
          background-color: var(--color-negative);
        }
      }

      &.income {
        &:before {
          border-color: var(--color-positive);
          background-color: var(--color-positive);
        }

        &:after {
          background-color: var(--color-positive);
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -25px;
        border-radius: 100%;
        border: solid 2px var(--color-blue);
        width: 10px;
        height: 10px;
        background-color: var(--primary-background-color);
      }

      &:after {
        content: '';
        position: absolute;
        top: 4px;
        left: -15px;
        width: 15px;
        height: 2px;
        background-color: var(--color-blue);
      }
    }
  }
}

/** GLOBAL ELEMENTS **/
.dropdown {
  width: 100%;
  position: relative;
  border: solid 1px var(--border-color);
  border-radius: 0.3rem;
  color: var(--primary-text-color);
  background-color: var(--primary-background-color);
  font-weight: var(--font-base);
  cursor: pointer;
  display: block;

  &.dropdown-active {
    border-color: var(--color-blue);
    & .dropdown-list {
      display: block;
      border-color: var(--color-blue);
    }
  }

  & .dropdown-selected-value {
    display: block;
    line-height: 1.5rem;
    padding: 0.5rem 0.9rem;
    height: 42px;

    & input {
      width: 100%;
    }
  }

  & .dropdown-list {
    display: none;
    position: absolute;
    left: -1px;
    right: -1px;
    top: 2.4rem;
    background-color: var(--primary-background-color);
    border-radius: 0 0 0.3rem 0.3rem;
    border: solid 1px var(--border-color);
    border-top: 0;
    z-index: 2222;
    overflow-y: auto;
    max-height: 320px;

    & .dropdown-item {
      line-height: 1.5rem;
      padding: 0.5rem 1.8rem;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.dropdown-group {
        font-weight: var(--font-bold);
        cursor: initial;
        padding-left: 0.9rem;
      }

      &.selected {
        background-color: var(--hover-color);
      }

      &:hover:not(.dropdown-group) {
        background-color: var(--hover-color);
      }
    }
  }
}

.field-schema-holder {
  & .field-schema {
    & .dropdown {
      text-indent: 0;

      &:not(.dropdown-active) {
        background-color: initial;
        border: initial;
      }
    }
  }
}

#form-general-error-message {
  text-align: center;
  font-size: var(--font-size-14px);
  color: var(--color-red);
}

.table-groups {
  width: 100%;
  position: relative;

  &:has(.collapse) {
    & > .table-group-row {
      &:not(.table-header, .group-header, .sum) {
        animation: collapse 0.3s linear forwards;
      }
    }
  }

  &:has(.expand) {
    & > .table-group-row {
      &:not(.table-header, .group-header, .sum) {
        animation: expand 0.3s linear forwards;
      }
    }
  }

  &:has(.group-header) {
    & .table-group-row {
      &:not(.table-header, .group-header) {
        & .table-group-cell {
          &:first-child {
            padding: 0.5rem 1.5rem;
          }
        }
      }
    }
  }

  & .table-group-row {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) repeat(4, minmax(100px, 120px)) minmax(100px, 140px);
    background-color: var(--secondary-background-color);
    line-height: 30px;
    border-left: solid 2px transparent;
    transition: all 0.2s linear;
    overflow-y: hidden;

    &.separator {
      grid-template-columns: 1fr !important;
      text-align: center;
      font-weight: bold;
      height: 60px;
      place-content: center;
      border-top: solid 2px var(--color-blue);
      border-bottom: solid 2px var(--color-blue);
    }

    &.table-header {
      background-color: var(--secondary-background-color);
      font-weight: var(--font-bold);
      text-align: center;
      border-bottom: solid 1px var(--hover-color);

      & .table-group-cell {
        border-right: 0;
      }
    }

    &.group-header {
      background-color: var(--primary-background-color) !important;
      border-left: solid 2px var(--primary-background-color) !important;
      font-weight: var(--font-bold);
    }

    &:not(.table-header, .group-header, .separator):nth-child(even) {
      background-color: var(--light-gray-color);
    }

    & .table-group-cell {
      padding: 0.5rem 0.5rem;
      text-overflow: ellipsis;
      overflow:hidden;
      white-space: nowrap;

      &.category-name {
        cursor: pointer;
      }

      &:has(input) {
        overflow: initial;
      }

      &:last-child {
        border-right: 0;
      }

      &.actions {
        padding: 0;
        gap: 0;

        & .btn {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          cursor: pointer;
          transition: 0.2s linear;

          &:hover {
            background-color: var(--hover-color);
          }

          &:before {
            font-family: var(--icon-font);
            position: absolute;
            font-size: var(--font-size-30px);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &.copy {
            &:before {
              font-size: var(--font-size-20px);
              content: "\e14d";
            }
          }

          &.play {
            &:before {
              font-size: var(--font-size-20px);
              content: "\e037";
            }
          }

          &.delete {
            &:before {
              font-size: var(--font-size-24px);
              content: "\e92b";
            }
          }

          &.edit {
            &:before {
              content: "\e409";
            }
          }
        }
      }
    }

    &:not(.table-header, .group-header, .separator):hover {
      background-color: var(--hover-color);
      border-left: solid 2px var(--color-blue);
    }
  }
}

.field-types {
  width: 100%;
  margin-bottom: 1rem;
  display: grid;
  grid-template-areas: "icon info";
  grid-template-columns: 100px 1fr;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: var(--hover-color);

    &.income {
      & .icon {
        & .mat-icon {
          color: var(--color-positive);
        }
      }
    }

    &.spent {
      & .icon {
        & .mat-icon {
          color: var(--color-negative);
        }
      }
    }
  }

  & .icon {
    grid-area: icon;

    & .mat-icon {
      text-align: center;
      display: block;
      width: calc(100% - 1rem);
      height: 100%;
      font-size: 3.8rem;
      transition: 0.2s ease-in-out;
    }
  }

  & .info {
    grid-area: info;

    .title {
      margin-bottom: 0;

      & h2 {
        color: var(--secondary-text-color);
        margin-bottom: 1rem;
      }
    }
  }
}

/** ANIMATIONS **/
@keyframes collapse {
  from { height: 46px; }
  to { height: 0; }
}

@keyframes expand {
  from { height: 0; }
  to { height: 46px; }
}
